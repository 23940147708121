import { Dispatch, SetStateAction } from "react"
import styled from "styled-components"

import { TextField } from "@material-ui/core"

import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import { useFetchParadiseHardwareVersions } from "src/data/paradise/paradiseDevices/queries/paradiseDeviceQueries"
import { DropdownSelect } from "src/ui/DropdownSelect/DropdownSelect"
import { Filter } from "src/ui/Filter/Filter"
import { spacing } from "src/ui/spacing"

export type TParadiseUserDevicesFilters = {
  description: string
  hardwareVersion: {
    name: string
    version_number: number
  } | null
}

export function ParadiseUserDevicesFilters({
  filter,
  setFilter,
}: {
  filter: TParadiseUserDevicesFilters
  setFilter: Dispatch<SetStateAction<TParadiseUserDevicesFilters>>
}) {
  const fetchHardwareVersions = useFetchParadiseHardwareVersions()
  const hardwareVersions = fetchHardwareVersions.data

  return (
    <div>
      <FilterWrapper>
        <Filter
          filters={[
            {
              id: "description",
              label: "Description",
              value: filter.description,
              active: !!filter.description,
              alwaysVisible: true,
              render: (
                <FilterContent>
                  <TextField
                    fullWidth
                    placeholder="Description"
                    value={filter.description}
                    onChange={(e) =>
                      setFilter((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                  />
                </FilterContent>
              ),
              onRemove: () =>
                setFilter((prev) => ({ ...prev, description: "" })),
              removeLabel: "Reset",
            },
            {
              id: "hardware_version",
              label: "Hardware version",
              value: filter.hardwareVersion?.name ?? "",
              active: !!filter.hardwareVersion,
              alwaysVisible: true,
              render: (
                <FilterContent>
                  {hardwareVersions && (
                    <DropdownSelect
                      label={
                        filter.hardwareVersion
                          ? getDeviceHardwareType(
                              filter.hardwareVersion.version_number
                            ).type
                          : "Hardware version"
                      }
                      options={hardwareVersions.map((version) => ({
                        label: `${version.name} (${version.version_number})`,
                        value: version.name,
                        version_number: version.version_number,
                      }))}
                      selectedValue={filter.hardwareVersion?.toString() ?? null}
                      onChange={({ option }) =>
                        setFilter((prev) => ({
                          ...prev,
                          hardwareVersion: {
                            name: option.value,
                            version_number: option.version_number,
                          },
                        }))
                      }
                    />
                  )}
                </FilterContent>
              ),
              onRemove: () =>
                setFilter((prev) => ({ ...prev, hardwareVersion: null })),
              removeLabel: "Reset",
            },
          ]}
        />
      </FilterWrapper>
    </div>
  )
}

const FilterWrapper = styled.div`
  display: block;
  margin-top: ${spacing.M};
`

const FilterContent = styled.div`
  padding: ${spacing.XS};
`
