import styled from "styled-components"

import { ParadiseOverviewExpandable } from "src/components/Paradise/ParadiseOverviewExpandable"
import { ParadiseSettingStatic } from "src/components/Paradise/ParadiseSettings/ParadiseSettingStatic"
import { ParadiseSettingText } from "src/components/Paradise/ParadiseSettings/ParadiseSettingText"
import { ParadiseUserRoles } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseUserOverview/ParadiseUserRoles"
import { TSettingContainerOnSaveReturnType } from "src/components/Settings/Setting/SettingContainer"
import { usePutParadiseUpdateUser } from "src/data/paradise/paradiseUsers/queries/paradiseUserQueries"
import { IParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { CopyText } from "src/ui/CopyText/CopyText"
import { MBadge } from "src/ui/MBadge/MBadge"
import { formatDate } from "src/utils/l10n"

export function ParadiseUserOverview({ user }: { user: IParadiseUser }) {
  const putParadiseUpdateUser = usePutParadiseUpdateUser()

  async function savePhoneNumber(
    newPhoneNumber: string
  ): TSettingContainerOnSaveReturnType {
    try {
      await putParadiseUpdateUser.mutateAsync({
        userId: user.id,
        body: {
          phone_number: newPhoneNumber,
        },
      })

      return {
        isSuccess: true,
      }
    } catch (error) {
      return {
        isSuccess: false,
      }
    }
  }

  return (
    <ParadiseOverviewExpandable rawJson={user}>
      <ParadiseSettingStatic title="Name" value={user.full_name} />
      <ParadiseSettingStatic
        title="Email"
        value={
          <CopyText
            value={user.email}
            displayText={user.email}
            width={16}
            copiedText="Email copied"
          />
        }
      />
      <ParadiseSettingText
        title="Phone number"
        value={user.phone_number ?? ""}
        onSave={savePhoneNumber}
      />
      <ParadiseSettingStatic
        title="Created at"
        value={formatDate({ date: user.created_at, timezone: "utc" })}
      />
      <ParadiseSettingStatic
        title="Updated at"
        value={formatDate({ date: user.updated_at, timezone: "utc" })}
      />
      <ParadiseSettingStatic
        title="Last seen at"
        value={formatDate({ date: user.last_seen_at ?? "", timezone: "utc" })}
      />
      <ParadiseSettingStatic
        title="Subscription"
        value={
          user.subscription_plan_id ? (
            <SubscriptionWrapper>
              <div>{user.subscription_plan_id}</div>
              <MBadge color="good" size="small">
                {user.subscription_status}
              </MBadge>
            </SubscriptionWrapper>
          ) : (
            "-"
          )
        }
      />
      <ParadiseUserRoles currentRoles={user.roles} userId={user.id} />
    </ParadiseOverviewExpandable>
  )
}

const SubscriptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
