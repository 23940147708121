import { useState } from "react"

import { ParadiseDeleteUserConfirmationDialog } from "src/components/Paradise/ParadiseUsers/ParadiseUserDetails/ParadiseDeleteUserConfirmationDialog"
import { ActionsWrapper } from "src/components/Paradise/sharedStyles"
import { useImpersonate } from "src/components/Paradise/useImpersonate"
import { chargebeeUrl, intercomUrl, kibanaUrl } from "src/constants/hrefs"
import { IParadiseUser } from "src/data/paradise/paradiseUsers/types/paradiseUserQueryTypes"
import { MButton } from "src/ui/Button/MButton"
import { ExternalLink } from "src/ui/Link/ExternalLink"

const externalLinks = (user: IParadiseUser) => {
  return {
    intercom: intercomUrl(user.email),
    kibana: kibanaUrl(user.id),
    chargebee: chargebeeUrl(user.id),
  }
}

export function ParadiseUserActions({ user }: { user: IParadiseUser }) {
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false)
  const impersonate = useImpersonate()

  return (
    <>
      <ActionsWrapper>
        <ExternalLink href={externalLinks(user).intercom}>
          Intercom
        </ExternalLink>
        <ExternalLink href={externalLinks(user).kibana}>Kibana</ExternalLink>
        <ExternalLink href={externalLinks(user).chargebee}>
          Chargebee
        </ExternalLink>
        <MButton
          variant="subtle"
          size="small"
          onClick={() => impersonate(user.id)}
          disabled={user.deleted}
        >
          Impersonate
        </MButton>
        <MButton
          variant="subtle"
          size="small"
          color="destructive"
          onClick={() => setShowDeleteConfirmDialog(true)}
        >
          Delete user
        </MButton>
      </ActionsWrapper>
      <ParadiseDeleteUserConfirmationDialog
        open={showDeleteConfirmDialog}
        email={user.email}
        userId={user.id}
        onClose={() => setShowDeleteConfirmDialog(false)}
      />
    </>
  )
}
